import React from 'react'

export const BulbIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="35"
      height="40"
      viewBox="0 0 35 40"
      fill="#f6b439"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.314 32.182a1.04 1.04 0 0 0-1.04 1.04c0 .515.255.996.564 1.301.309.306.66.491 1.03.633.736.284 1.57.39 2.411.39.842 0 1.676-.106 2.412-.39.369-.142.72-.327 1.03-.633.309-.305.564-.786.564-1.3a1.04 1.04 0 0 0-1.04-1.041 1.04 1.04 0 0 0-1.042 1.04c0-.086.07-.192.055-.177a1.162 1.162 0 0 1-.315.17c-.375.144-1.023.25-1.664.25-.64 0-1.289-.106-1.664-.25a1.06 1.06 0 0 1-.293-.158 1.04 1.04 0 0 0-1.008-.875zM15.629 35.836a1.04 1.04 0 0 0-1.27.742 1.04 1.04 0 0 0 .743 1.272 8.495 8.495 0 0 0 4.355 0 1.04 1.04 0 0 0 .742-1.272 1.04 1.04 0 0 0-1.271-.742 6.323 6.323 0 0 1-3.3 0z" />
      <path d="M17.275 9.453c-4.595 0-8.359 3.696-8.359 8.246 0 2.512 1.586 5.921 3.104 8.973a93.896 93.896 0 0 0 3.033 5.594 1.04 1.04 0 0 0 1.426.361 1.04 1.04 0 0 0 .36-1.428s-1.48-2.485-2.956-5.455c-1.477-2.97-2.885-6.577-2.885-8.045 0-3.408 2.789-6.164 6.277-6.164 3.49 0 6.28 2.756 6.28 6.164 0 1.578-1.408 5.164-2.883 8.11-1.476 2.945-2.955 5.384-2.955 5.384a1.04 1.04 0 0 0 .351 1.428 1.04 1.04 0 0 0 1.428-.35s1.516-2.499 3.035-5.53c1.52-3.033 3.106-6.464 3.106-9.042 0-4.55-3.765-8.246-8.362-8.246zM17.271 1.742a1.04 1.04 0 0 0-1.04 1.041v4.115a1.04 1.04 0 0 0 1.04 1.041 1.04 1.04 0 0 0 1.041-1.04V2.782a1.04 1.04 0 0 0-1.04-1.04ZM28.533 16.66a1.04 1.04 0 0 0-1.074 1.004 1.04 1.04 0 0 0 1.004 1.076l3.935.135a1.04 1.04 0 0 0 1.077-1.004 1.04 1.04 0 0 0-1.006-1.076zM2.123 16.793a1.04 1.04 0 0 0-1.041 1.041 1.04 1.04 0 0 0 1.041 1.041h4.078a1.04 1.04 0 0 0 1.041-1.041 1.04 1.04 0 0 0-1.04-1.041ZM7.285 6.545a1.04 1.04 0 0 0-1.473.012 1.04 1.04 0 0 0 .012 1.472l2.65 2.608a1.04 1.04 0 0 0 1.473-.012 1.04 1.04 0 0 0-.011-1.47ZM27.992 6.246a1.04 1.04 0 0 0-.738.299l-2.65 2.61a1.04 1.04 0 0 0-.012 1.472 1.04 1.04 0 0 0 1.472.01l2.65-2.608a1.04 1.04 0 0 0 .01-1.472 1.04 1.04 0 0 0-.732-.31z" />
      <path d="M19.064 15.664c-.716.127-1.322.369-1.828.537-1.011.337-1.713.557-3.502-.328a1.04 1.04 0 0 0-1.394.47 1.04 1.04 0 0 0 .472 1.395c2.205 1.09 3.898.833 5.083.438 1.184-.395 1.773-.76 3.068-.373a1.04 1.04 0 0 0 1.295-.701 1.04 1.04 0 0 0-.7-1.293 5.293 5.293 0 0 0-2.494-.145zM13.703 18.982a1.04 1.04 0 0 0-.662 1.315l1.957 5.941a1.04 1.04 0 0 0 1.315.664 1.04 1.04 0 0 0 .662-1.314l-1.957-5.943a1.04 1.04 0 0 0-1.315-.663zM21.014 18.436a1.04 1.04 0 0 0-1.313.662l-2.137 6.486a1.04 1.04 0 0 0 .663 1.314 1.04 1.04 0 0 0 1.314-.662l2.137-6.486a1.04 1.04 0 0 0-.664-1.314z" />
    </svg>
  )
}
