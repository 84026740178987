import { GetServerSidePropsContext } from 'next'
import { SSRConfig } from 'next-i18next'
import { routes } from '~/common/routes'
import { AuthenticationError } from '~/server-api/auth'
import { checkOnboardingStatus } from '~/server-api/get-onboarding-status'

export interface GetOrganizationServerSideProps {
  ctx: GetServerSidePropsContext
  translations: SSRConfig
}

export const getOrganizationServerSideProps = async ({
  ctx,
  translations,
}: GetOrganizationServerSideProps) => {
  try {
    const redirect = await checkOnboardingStatus(ctx)
    if (redirect) {
      return redirect
    }
  } catch (error) {
    if (error instanceof AuthenticationError) {
      return {
        redirect: {
          destination: routes.login,
          permanent: false,
        },
      }
    }

    return {
      redirect: {
        destination: routes.accessForbidden,
        permanent: false,
      },
    }
  }

  return {
    props: { ...translations },
  }
}
