import React from 'react'

export const ErrorTriangleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="currentColor"
    {...props}
  >
    <path d="M18 3.55c-1.355 0-2.71.658-3.467 1.973L2.467 26.506C.958 29.13 2.907 32.5 5.936 32.5h24.128c3.028 0 4.978-3.369 3.47-5.994L21.468 5.523C20.712 4.207 19.355 3.551 18 3.551Zm0 1.936c.669 0 1.338.346 1.734 1.035L31.8 27.504c.787 1.373-.151 2.996-1.735 2.996H5.936c-1.585 0-2.523-1.622-1.735-2.996v-.002l12.065-20.98c.397-.69 1.065-1.036 1.734-1.036Zm0 7.014a1 1 0 0 0-1 1v8.182a1 1 0 0 0 1 1 1 1 0 0 0 1-1V13.5a1 1 0 0 0-1-1z" />
    <path d="M18.688 24.758a1 1 0 0 0-1.413.068l-.015.016a1 1 0 0 0 .068 1.414 1 1 0 0 0 1.412-.068l.016-.016a1 1 0 0 0-.069-1.414Z" />
  </svg>
)
