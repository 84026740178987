import React, { FC } from 'react'
import { SpaceProps } from 'styled-system'
import { Container } from '../container'
import { ListItem, ListItemProps } from '../list-item'

export interface ListProps extends SpaceProps {
  items: ListItemProps[]
}

export const List: FC<ListProps> = ({ items, ...spacing }) => (
  <Container {...spacing}>
    {items.map((item) => {
      return <ListItem {...item} key={item.id} />
    })}
  </Container>
)
