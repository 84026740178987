import React from 'react'

export const IntercomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="60"
      fill="currentColor"
      {...props}
      viewBox="0 0 60 61"
    >
      <path d="M52.086 33.017c0 .535-.211 1.047-.587 1.426a1.993 1.993 0 0 1-2.829 0 2.025 2.025 0 0 1-.586-1.426V15c0-.535.21-1.048.586-1.426a1.993 1.993 0 0 1 2.83 0c.375.378.586.891.586 1.426v18.017ZM51.39 45.5c-.264.267-7.705 6.517-21.347 6.517-13.641 0-21.032-6.217-21.347-6.484a1.98 1.98 0 0 1-.692-1.351 1.999 1.999 0 0 1 .461-1.449 1.991 1.991 0 0 1 2.81-.217c.117.084 6.764 5.517 18.752 5.517s18.684-5.466 18.75-5.516a2.023 2.023 0 0 1 2.828.216 1.993 1.993 0 0 1-.198 2.784l-.017-.017ZM7.986 15c.03-.536.27-1.04.666-1.398.397-.36.918-.546 1.45-.519.495.029.96.24 1.308.594.348.354.554.825.578 1.323v17.983c0 .535-.21 1.048-.586 1.426a1.992 1.992 0 0 1-2.83 0 2.025 2.025 0 0 1-.586-1.425V15Zm10.037-4c.03-.536.27-1.04.666-1.398.397-.36.918-.546 1.45-.519.494.029.96.24 1.308.594.348.354.554.825.578 1.323v26.667c0 .535-.211 1.047-.586 1.426a1.993 1.993 0 0 1-2.83 0 2.025 2.025 0 0 1-.586-1.426V11Zm10.087-1c0-.535.21-1.048.586-1.426a1.993 1.993 0 0 1 2.83 0c.375.378.585.891.585 1.426v29c0 .535-.21 1.048-.586 1.426a1.993 1.993 0 0 1-2.83 0A2.025 2.025 0 0 1 28.11 39V10Zm9.92 1c0-.535.212-1.048.587-1.426a1.992 1.992 0 0 1 2.83 0c.374.378.585.891.585 1.426v26.667c0 .535-.21 1.047-.586 1.426a1.993 1.993 0 0 1-2.83 0 2.025 2.025 0 0 1-.585-1.426V11ZM52.632 0H7.523a7.422 7.422 0 0 0-2.867.556 7.469 7.469 0 0 0-2.438 1.621A7.537 7.537 0 0 0 .583 4.616 7.585 7.585 0 0 0 0 7.5v45c.004.99.202 1.97.583 2.884.38.913.936 1.742 1.635 2.439a7.467 7.467 0 0 0 2.438 1.621A7.42 7.42 0 0 0 7.523 60h45.108a7.418 7.418 0 0 0 2.864-.554 7.464 7.464 0 0 0 2.436-1.617 7.533 7.533 0 0 0 1.636-2.433c.381-.912.581-1.89.588-2.88V7.5a7.59 7.59 0 0 0-.582-2.88 7.539 7.539 0 0 0-1.63-2.437A7.467 7.467 0 0 0 55.51.561 7.422 7.422 0 0 0 52.648 0" />
    </svg>
  )
}
