import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import React from 'react'

export interface SidebarProviderProps {
  children: ReactNode | ReactNode[]
}

export interface SidebarProviderDefinition {
  collapsed: boolean
  selectedItemId: string
  setCollapsed: Dispatch<SetStateAction<boolean>>
  setSelectedItemId: Dispatch<SetStateAction<string>>
}

const initData: SidebarProviderDefinition = {
  collapsed: false,
  selectedItemId: '',
  setCollapsed: () => {},
  setSelectedItemId: () => {},
}

const SidebarContext = createContext<SidebarProviderDefinition>(initData)

const SidebarProvider = ({ children }: SidebarProviderProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [selectedItemId, setSelectedItemId] = useState<string>('')

  const { pathname } = useRouter()
  const initialItemId = useMemo(() => pathname.split('/')[2], [pathname])
  useEffect(() => {
    setSelectedItemId(initialItemId)
  }, [initialItemId])

  return (
    <SidebarContext.Provider value={{ collapsed, setCollapsed, selectedItemId, setSelectedItemId }}>
      {children}
    </SidebarContext.Provider>
  )
}

export { SidebarContext, SidebarProvider }
