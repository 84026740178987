import React from 'react'

export const QuestionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        stroke="#A9A49C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.67188 9.60358C9.85996 9.06891 10.2312 8.61807 10.7198 8.33089C11.2085 8.04371 11.783 7.93873 12.3416 8.03455C12.9002 8.13037 13.4069 8.4208 13.7719 8.8544C14.1369 9.28801 14.3367 9.8368 14.3359 10.4036C14.3359 12.0036 11.9359 12.8036 11.9359 12.8036"
        stroke="#A9A49C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="16" r="1" fill="#A9A49C" />
    </svg>
  )
}
