import { useState } from 'react'

export const useToastMessage = (interval = 1000) => {
  const [isShown, setIsShown] = useState<boolean>(false)

  const show = () => {
    setIsShown(true)
    setTimeout(() => {
      setIsShown(false)
    }, interval)
  }

  return {
    show,
    isShown,
  }
}
