import React from 'react'

export const SettingsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M5 9a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm6 0a4.002 4.002 0 0 1-3 3.874V17a1 1 0 1 1-2 0v-4.126A4.002 4.002 0 0 1 7 5a4 4 0 0 1 4 4Zm6 3a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-1-6v4.126A4.002 4.002 0 0 0 17 18a4 4 0 0 0 1-7.874V6a1 1 0 1 0-2 0Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
