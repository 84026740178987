import React from 'react'

export const MastercardIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" {...props}>
      <path
        fill="#252525"
        d="M22 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z"
      />
      <path fill="#EB001B" d="M9 13A5 5 0 1 0 9 3a5 5 0 0 0 0 10Z" />
      <path fill="#F79E1B" d="M15 13a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z" />
      <path
        fill="#FF5F00"
        fillRule="evenodd"
        d="M12 4c1.214.912 2 2.364 2 4a4.992 4.992 0 0 1-2 4 4.992 4.992 0 0 1-2-4c0-1.636.786-3.088 2-4Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
