import React from 'react'

export const CloseBoldIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 3a1 1 0 0 0-.707.293l-8 8a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0l8-8a1 1 0 0 0 0-1.414A1 1 0 0 0 12 3Z" />
      <path d="M4 3a1 1 0 0 0-.707.293 1 1 0 0 0 0 1.414l8 8a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.414l-8-8A1 1 0 0 0 4 3Z" />
    </svg>
  )
}
