import React from 'react'

export const RoundArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m7.362 4.789 2.82 2.525c.1.09.18.197.235.315a.883.883 0 0 1 0 .744.971.971 0 0 1-.235.315l-2.82 2.525c-.686.615-1.862.176-1.862-.692v-5.05c0-.867 1.176-1.296 1.862-.682Z" />
    </svg>
  )
}
