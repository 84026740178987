import React, { FC, ReactElement } from 'react'
import { SpaceProps } from 'styled-system'
import { PlacementType, Tooltip, Container } from 'ui'
import { IconContainer, ListItemButton, ListItemLink, ListItemText } from './styled'

export interface ListItemProps extends SpaceProps {
  name: string
  id?: string //TODO: check if it is needed when fixing Flow ListItems
  icon?: ReactElement
  onClick?: () => void // if onClick is used the ListItem is a button
  hideName?: boolean
  isSelected?: boolean
  tooltipPlacement?: PlacementType
  as?: 'span' | 'button' | 'a' // use span in case of wrapping ListItem in Next.js Link to not create duplicate `a` element
  href?: string // if href is used the ListItem is an anchor
}

export interface ListItemButtonProps {
  isSelected?: boolean
}

export const ListItem: FC<ListItemProps> = ({
  name,
  icon,
  hideName,
  onClick,
  isSelected,
  tooltipPlacement = 'right',
  href,
  as,
  ...spaceProps
}) => {
  const commonContent = (
    <>
      <Tooltip text={name} disabled={!hideName} placement={tooltipPlacement}>
        {icon && <IconContainer>{icon}</IconContainer>}
      </Tooltip>
      {!hideName && <ListItemText as="span">{name}</ListItemText>}
    </>
  )

  return (
    <>
      {href ? (
        <ListItemLink href={href} target="_blank" rel="noreferrer" as={as || 'a'} {...spaceProps}>
          {commonContent}
        </ListItemLink>
      ) : (
        <ListItemButton
          isSelected={isSelected}
          onClick={onClick}
          as={as || 'button'}
          {...spaceProps}
        >
          {commonContent}
        </ListItemButton>
      )}
    </>
  )
}
