import React from 'react'

export const ZendeskIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 16 16"
    >
      <path
        d="M7.39075 4.93963V13.8626H7.07829e-07L7.39075 4.93963ZM7.39075 1.73713C7.392 3.77888 5.73725 5.43463 3.69525 5.43463C1.65325 5.43463 -0.00124929 3.77838 7.07829e-07 1.73663L7.39075 1.73713ZM8.60825 13.8631C8.60825 11.8221 10.2628 10.1676 12.3038 10.1676C14.3448 10.1676 15.9993 11.8221 15.9993 13.8631H8.60825ZM8.60825 10.6606V1.73663H16L8.60825 10.6606Z"
        fill="currentColor"
      />
    </svg>
  )
}
