import React, { SVGProps, FC } from 'react'

export const CodeFileIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
    <g stroke="#A9A49C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4" />
      <path d="M14 2v6h6M9 18l3-3-3-3m-4 0-3 3 3 3" />
    </g>
  </svg>
)
