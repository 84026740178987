import React, { SVGProps, FC } from 'react'

export const ClockWarningIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.297 2.006A9.998 9.998 0 0 0 9.07 21.563a10 10 0 0 0 6.278-.14 1 1 0 0 0 .607-1.277 1 1 0 0 0-1.277-.607A7.998 7.998 0 0 1 5.596 7.207a7.997 7.997 0 0 1 9.14-2.723 7.994 7.994 0 0 1 5.252 7.961 1 1 0 0 0 .942 1.055 1 1 0 0 0 1.054-.943 10.003 10.003 0 0 0-9.688-10.55Z" />
    <path d="M12 6a1 1 0 0 0-1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.414L13 11.586V7a1 1 0 0 0-1-1Zm7 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zm0 6a1 1 0 0 0-1 1v.01a1 1 0 0 0 1 1 1 1 0 0 0 1-1V22a1 1 0 0 0-1-1z" />
  </svg>
)
