import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider as NextThemeProvider } from 'next-themes'
import type { AppProps } from 'next/app'
import React, { useState } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import { ModalProvider } from 'ui'
import { darkTheme, theme } from '~/common/theme'
import { ToastContainer } from '~/components/toast-container'
import { OnboardingProvider } from '~/contexts/onboarding-context'
import { SidebarProvider } from '~/contexts/sidebar-provider'
import { UserProvider } from '~/contexts/user-provider'
import { GlobalStyles } from '~/styles/global'
import '../src/styles/fonts.css'
import { NextPageWithLayout } from './@types/page'

interface AppPropsWithLayout extends AppProps {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page)
  const { dehydratedState } = pageProps as { dehydratedState: unknown }
  const [queryClient] = useState(() => new QueryClient())

  return (
    <ThemeProvider theme={Component.theme === 'dark' ? darkTheme : theme}>
      <NextThemeProvider
        forcedTheme={Component.theme}
        disableTransitionOnChange
        enableSystem={false}
        defaultTheme="light"
      >
        {/* <ThemeSwitch /> */}
        <ToastContainer />
        <GlobalStyles />
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <SkeletonTheme
              baseColor={theme.colors.greyLight}
              highlightColor={theme.colors.greyLighter}
              enableAnimation
              borderRadius={8}
            >
              <OnboardingProvider>
                <UserProvider>
                  <ModalProvider>
                    <SidebarProvider>{getLayout(<Component {...pageProps} />)}</SidebarProvider>
                  </ModalProvider>
                </UserProvider>
              </OnboardingProvider>
            </SkeletonTheme>
          </Hydrate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </NextThemeProvider>
    </ThemeProvider>
  )
}

export default appWithTranslation(MyApp)
