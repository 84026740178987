import React from 'react'

export const CaseSensitiveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.176 1.918a.75.75 0 0 0-.719.535L1.281 13.121a.75.75 0 0 0 .504.932.75.75 0 0 0 .934-.504l.84-2.819h3.236l.84 2.819a.75.75 0 0 0 .933.504.75.75 0 0 0 .504-.932L5.895 2.453a.75.75 0 0 0-.72-.535zm0 3.379L6.348 9.23H4.004zm7.318 1.285c-1.066-.053-2.089.31-2.777.746a.75.75 0 0 0-.233 1.035.75.75 0 0 0 1.036.23c.37-.234 1.288-.543 1.898-.513.305.015.508.097.62.2.11.101.212.251.212.689v.07c-.496.092-1.165.17-1.992.432-.522.165-1.05.396-1.488.799a2.355 2.355 0 0 0-.754 1.722c0 .701.344 1.349.867 1.672.523.323 1.123.366 1.703.287a4.527 4.527 0 0 0 1.676-.59.75.75 0 0 0 .738.723.75.75 0 0 0 .75-.75V8.969c0-.738-.25-1.385-.701-1.797-.451-.412-1.022-.563-1.555-.59Zm.756 3.986v.758c-.408.549-1.212 1.05-1.865 1.139-.347.047-.608-.013-.713-.078-.106-.065-.156-.088-.156-.395 0-.306.08-.445.27-.619s.522-.342.927-.47c.494-.158 1.012-.241 1.537-.335z" />
    </svg>
  )
}
