import React, { SVGProps } from 'react'

export const SidebarExpandIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5 2C3.355 2 2 3.355 2 5v14c0 1.645 1.355 3 3 3h14c1.645 0 3-1.355 3-3V5c0-1.645-1.355-3-3-3H5zm0 2h3v16H5c-.564 0-1-.436-1-1V5c0-.564.436-1 1-1zm5 0h9c.564 0 1 .436 1 1v14c0 .564-.436 1-1 1h-9V4zm4 4a1 1 0 0 0-.707.293 1 1 0 0 0 0 1.414L15.586 12l-2.293 2.293a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0l3-3a1 1 0 0 0 0-1.414l-3-3A1 1 0 0 0 14 8z" />
    </svg>
  )
}
