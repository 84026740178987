import axios, { AxiosResponse } from 'axios'
import cookie, { CookieSerializeOptions } from 'cookie'
import jwt from 'jsonwebtoken'
import { get } from 'lodash'
import { ServerResponse } from 'node:http'

interface WellKnownConfiguration {
  issuer: string
  jwks_uri: string
  authorization_endpoint: string
  token_endpoint: string
  userinfo_endpoint: string
  end_session_endpoint: string
  check_session_iframe: string
  revocation_endpoint: string
  introspection_endpoint: string
  device_authorization_endpoint: string
  frontchannel_logout_supported: boolean
  frontchannel_logout_session_supported: boolean
  backchannel_logout_supported: boolean
  backchannel_logout_session_supported: boolean
  scopes_supported: string[]
  claims_supported: string[]
  grant_types_supported: string[]
  response_types_supported: string[]
  response_modes_supported: string[]
  token_endpoint_auth_methods_supported: string[]
  id_token_signing_alg_values_supported: string[]
  subject_types_supported: string[]
  code_challenge_methods_supported: string[]
  request_parameter_supported: boolean
}

export const getWellKnownConfig: () => Promise<WellKnownConfiguration> = async () => {
  const openIdConfig = await axios.get(
    `${process.env.NEXT_PUBLIC_IDENTITY_SERVER_URL}/.well-known/openid-configuration`
  )
  return openIdConfig.data
}

export const getAuthorizeEndpoint = (schema: WellKnownConfiguration) => {
  return schema.authorization_endpoint
}

export const getTokenEndpoint = (schema: WellKnownConfiguration) => {
  return schema.token_endpoint
}

export const getTokensFromResponse = ({ data }: AxiosResponse) => {
  return {
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    idToken: data.id_token,
  }
}

type JwtTokens = {
  accessToken: string
  idToken: string
  organizationToken?: string
  backOfficeToken?: string
}

type AuthTokens = {
  refreshToken: string
} & JwtTokens

type DecodedJwtTokens = {
  accessToken: jwt.JwtPayload | null
  idToken: jwt.JwtPayload | null
  organizationToken?: jwt.JwtPayload | null
  backOfficeToken?: jwt.JwtPayload | null
}

const calcDifferenceInSec = (date1: Date, date2: Date) => {
  return Math.abs(date1.getTime() - date2.getTime()) / 1000
}

// Calculate expiration date based on the difference between the issued at and expiration date
// Return number to be used as maxAge in cookie
// Expiration property doesn't set in cookie because it's not supported by all browsers (doesn't work in Safari and Chrome at least)
const calculateExpirationDate = (
  decodedTokens: DecodedJwtTokens,
  tokenType: keyof DecodedJwtTokens
): number => {
  const skewInSeconds = 300
  const defaultMaxAge = 60 * 60 // 1 hour
  const expirationDate = decodedTokens[tokenType]?.exp
  const issuedAt = decodedTokens[tokenType]?.iat
  return expirationDate && issuedAt
    ? calcDifferenceInSec(new Date(expirationDate * 1000), new Date(issuedAt * 1000)) -
        skewInSeconds
    : defaultMaxAge
}

export const setCookies = (res: ServerResponse, tokens: AuthTokens, removePkce = false) => {
  const decodedTokens = decodeJwtTokens(tokens)
  const basicConfig: CookieSerializeOptions = {
    secure: process.env.NODE_ENV !== 'development',
    sameSite: 'lax',
    path: '/',
  }
  const tokensArray = [
    {
      name: 'access_token',
      value: tokens.accessToken,
      options: {
        ...basicConfig,
        maxAge: calculateExpirationDate(decodedTokens, 'accessToken'),
      },
    },
    {
      name: 'refresh_token',
      value: tokens.refreshToken,
      options: { ...basicConfig, maxAge: 2592000 /* 30 days */ },
    },
    {
      name: 'id_token',
      value: tokens.idToken,
      options: {
        ...basicConfig,
        maxAge: calculateExpirationDate(decodedTokens, 'idToken'),
      },
    },
  ]
  if (tokens.organizationToken) {
    tokensArray.push({
      name: 'organization_token',
      value: tokens.organizationToken,
      options: {
        ...basicConfig,
        maxAge: calculateExpirationDate(decodedTokens, 'organizationToken'),
      },
    })
  }
  if (tokens.backOfficeToken) {
    tokensArray.push({
      name: 'backoffice_token',
      value: tokens.backOfficeToken,
      options: {
        ...basicConfig,
        maxAge: calculateExpirationDate(decodedTokens, 'backOfficeToken'),
      },
    })
  }
  if (removePkce) {
    tokensArray.push({
      name: 'pkce.code_verifier',
      value: '',
      options: { ...basicConfig, maxAge: 0 },
    })
  }
  res.setHeader(
    'Set-Cookie',
    tokensArray.map(({ name, value, options }) => cookie.serialize(name, value, { ...options }))
  )
}

export const decodeJwtTokens = (tokens: JwtTokens): DecodedJwtTokens => {
  return {
    accessToken: decodeJwtToken(tokens.accessToken),
    idToken: decodeJwtToken(tokens.idToken),
    organizationToken: tokens.organizationToken
      ? decodeJwtToken(tokens.organizationToken)
      : undefined,
    backOfficeToken: tokens.backOfficeToken ? decodeJwtToken(tokens.backOfficeToken) : undefined,
  }
}

export const decodeJwtToken = (token: string): jwt.JwtPayload | null => {
  try {
    const decoded = jwt.decode(token)

    if (!decoded || typeof decoded === 'string') {
      return null
    }

    return decoded
  } catch (err) {
    console.log(`Error decoding token. Error: ${err}`)

    return null
  }
}

export const refreshSession = async (refreshToken: string): Promise<AuthTokens> => {
  const { data } = await axios.post(
    `${process.env.NEXT_PUBLIC_ENVIRONMENT}/api/auth/refresh-session`,
    {
      refresh_token: refreshToken,
    }
  )
  return {
    accessToken: data.response.access_token,
    refreshToken: data.response.refresh_token,
    idToken: data.response.id_token,
  }
}

export const getOrganizationToken = async (
  organizationId: string,
  accessToken: string,
  idToken: string
): Promise<string | undefined> => {
  const { data } = await axios.post(
    `${process.env.NEXT_PUBLIC_API_URL}/organizations/${organizationId}/token`,
    {
      idToken,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  return data.accessToken
}

export const getBackOfficeToken = async (
  accessToken: string,
  idToken: string
): Promise<string | undefined> => {
  try {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/back-office/token`,
      {
        idToken,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    return data.backOfficeToken
  } catch (err) {
    console.error(err)
    return undefined
  }
}

type SessionOptions = {
  organizationId?: string
  isBackOffice?: boolean
}

export class AuthenticationError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AuthenticationError'
  }
}

export class Session {
  private refreshToken: string
  public idToken: string
  public accessToken: string
  public organizationToken: string | undefined
  public backOfficeToken: string | undefined

  constructor(tokens: AuthTokens) {
    this.refreshToken = tokens.refreshToken
    this.accessToken = tokens.accessToken
    this.idToken = tokens.idToken
    this.organizationToken = tokens.organizationToken
    this.backOfficeToken = tokens.backOfficeToken
  }

  public static fromResponse(response: AxiosResponse) {
    const accessToken = response.data.access_token
    const refreshToken = response.data.refresh_token
    const idToken = response.data.id_token

    if (!accessToken || !refreshToken || !idToken) {
      throw new AuthenticationError('Missing tokens when creating session from response')
    }

    return new Session({
      accessToken,
      idToken,
      refreshToken,
    })
  }

  /*
   * Removes cookies that have conflict between each other and caused 502 error
   */
  public static removeCookies(res: ServerResponse) {
    const basicConfig: CookieSerializeOptions = {
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'strict',
      path: '/',
      maxAge: 0,
    }

    const cookiesToRemove = ['backoffice_token', 'organization_token']

    res.setHeader(
      'Set-Cookie',
      cookiesToRemove.map((name) => cookie.serialize(name, '', { ...basicConfig }))
    )
  }

  public static async fromCookies(
    cookies: Partial<{ [key: string]: string }>,
    options?: SessionOptions
  ) {
    let accessToken = get(cookies, 'access_token')
    let refreshToken = get(cookies, 'refresh_token')
    let idToken = get(cookies, 'id_token')
    let organizationToken = get(cookies, 'organization_token')
    let backOfficeToken = get(cookies, 'backoffice_token')

    if (!refreshToken) {
      throw new AuthenticationError('No refresh token found')
    }

    if (!accessToken || !idToken) {
      try {
        const refreshedTokens = await refreshSession(refreshToken)

        accessToken = refreshedTokens.accessToken
        refreshToken = refreshedTokens.refreshToken
        idToken = refreshedTokens.idToken
      } catch (err) {
        console.error(err)
        throw new AuthenticationError('Failed to refresh session. ' + err)
      }
    }

    if (options?.organizationId && !organizationToken) {
      organizationToken = await getOrganizationToken(options.organizationId, accessToken, idToken)

      if (!organizationToken) {
        throw new Error('No organization token found')
      }
    }

    if (options?.isBackOffice && !backOfficeToken) {
      backOfficeToken = await getBackOfficeToken(accessToken, idToken)

      if (!backOfficeToken) {
        throw new Error('No backoffice token found')
      }
    }

    if (organizationToken && options?.organizationId) {
      const decodedOrganizationToken = decodeJwtToken(organizationToken)
      const organizationIdFromToken = decodedOrganizationToken?.act.OrganizationId
      if (organizationIdFromToken !== options.organizationId) {
        organizationToken = await getOrganizationToken(options.organizationId, accessToken, idToken)
      }
    }

    return new Session({
      accessToken,
      idToken,
      organizationToken,
      refreshToken,
      backOfficeToken,
    })
  }

  public setCookies(res: ServerResponse, removePkce = false) {
    setCookies(
      res,
      {
        accessToken: this.accessToken,
        idToken: this.idToken,
        refreshToken: this.refreshToken,
        organizationToken: this.organizationToken,
        backOfficeToken: this.backOfficeToken,
      },
      removePkce
    )

    return this
  }
}
