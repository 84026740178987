import React from 'react'

export const ConnectIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
      <path
        stroke="#373234"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 10H3l4-4m0 8h14l-4 4"
      />
    </svg>
  )
}
