import axios from 'axios'
import { parseCookies } from 'nookies'
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { getWellKnownConfig } from '~/server-api'

interface UserContextProps {
  email?: string
}

const UserContext = createContext<UserContextProps>({
  email: '',
})

export const useUserContext = () => useContext(UserContext)

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<UserContextProps>()

  useEffect(() => {
    const getUser = async () => {
      const wellKnown = await getWellKnownConfig()
      const response = await axios.get(wellKnown.userinfo_endpoint, {
        headers: {
          Authorization: `Bearer ${parseCookies().access_token}`,
        },
      })
      setUser(response.data)
    }
    getUser()
  }, [])

  return <UserContext.Provider value={{ email: user?.email }}>{children}</UserContext.Provider>
}
