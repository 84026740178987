import React from 'react'

export const AmexIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      {...props}
    >
      <path
        fill="#016FD0"
        d="M22 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z"
      />
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="M13.764 13.394V7.692l10.147.01v1.574l-1.173 1.254 1.173 1.265v1.608H22.04l-.996-1.098-.988 1.102-6.291-.013Z"
        clipRule="evenodd"
      />
      <path
        fill="#016FD0"
        fillRule="evenodd"
        d="M14.441 12.769v-4.45h3.773v1.026h-2.551v.695h2.49v1.008h-2.49v.684h2.55v1.037h-3.772Z"
        clipRule="evenodd"
      />
      <path
        fill="#016FD0"
        fillRule="evenodd"
        d="m18.195 12.769 2.088-2.227-2.088-2.222h1.616l1.276 1.41 1.279-1.41h1.546v.035l-2.043 2.187 2.043 2.164v.063H22.35l-1.298-1.424-1.285 1.424h-1.572Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="M14.237 2.632h2.446l.86 1.95v-1.95h3.019l.52 1.461.523-1.461h2.306v5.701H11.725l2.512-5.701Z"
        clipRule="evenodd"
      />
      <path
        fill="#016FD0"
        fillRule="evenodd"
        d="m14.7 3.251-1.973 4.446h1.354l.372-.89h2.018l.372.89h1.388L16.265 3.25H14.7Zm.17 2.558.592-1.415.592 1.415H14.87Z"
        clipRule="evenodd"
      />
      <path
        fill="#016FD0"
        fillRule="evenodd"
        d="M18.213 7.696V3.25l1.903.006.98 2.733.985-2.74h1.832v4.446l-1.18.01V4.653l-1.112 3.043h-1.075L19.41 4.642v3.054h-1.197Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
