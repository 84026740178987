import React, { ReactNode } from 'react'
import { SpaceProps } from 'styled-system'

export interface RadioButtonStylesProps {
  reverse?: boolean
  border?: boolean
  disabled?: boolean
  desc?: string
}
export interface RadioButtonProps extends SpaceProps, RadioButtonStylesProps {
  label: string
  name: string
  value: string
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  desc?: string
  preFix?: ReactNode
}
