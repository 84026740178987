import { Disclosure } from '@headlessui/react'
import React, { FC, useState } from 'react'
import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'
import { ChevronUpIcon, Container, Tooltip } from 'ui'
import { ListItem, ListItemProps } from '../list-item'
import {
  ArrowBtn,
  ListHeaderText,
  DisclosureButtonContainer,
  ListItemsContainer,
  DisclosureButtonWrapper,
} from './styled'

export interface CollapsibleListStyleProps extends SpaceProps {}

export interface CollapsibleListProps {
  name: string
  itemList: ListItemProps[]
  icon: React.ReactElement
  isOpenByDefault: boolean
  selectedItemId?: string
  hideName?: boolean
}

export interface ListItemsProps {
  open: boolean
}

export type GeneralCollapsibleListProps = CollapsibleListStyleProps & CollapsibleListProps

export const CollapsibleList: FC<GeneralCollapsibleListProps> = ({
  itemList,
  name,
  icon,
  isOpenByDefault,
  selectedItemId,
  hideName,
  ...spacing
}) => {
  const [hover, setHover] = useState(false)
  const theme = useTheme()

  return (
    <Container {...spacing}>
      <Disclosure defaultOpen={isOpenByDefault}>
        {({ open }) => (
          <>
            <DisclosureButtonWrapper>
              <Disclosure.Button as="div">
                <DisclosureButtonContainer
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <Tooltip text={name} disabled={!hideName} placement="right">
                    {hover ? (
                      <ArrowBtn open={open}>
                        <ChevronUpIcon fill={theme.colors.greyDark} />
                      </ArrowBtn>
                    ) : (
                      <Container as="span" display="flex">
                        {icon}
                      </Container>
                    )}
                  </Tooltip>
                  {!hideName && (
                    <ListHeaderText variant="captionMedium">{name.toUpperCase()}</ListHeaderText>
                  )}
                </DisclosureButtonContainer>
              </Disclosure.Button>
            </DisclosureButtonWrapper>
            {itemList.length > 0 && (
              <ListItemsContainer open={open}>
                {itemList.map((item) => (
                  <Disclosure.Panel key={item.id}>
                    <ListItem
                      id={item.id}
                      name={item.name}
                      icon={item.icon}
                      onClick={item.onClick}
                      hideName={hideName}
                      isSelected={selectedItemId === item.id}
                      key={item.id}
                      tooltipPlacement="right"
                    />
                  </Disclosure.Panel>
                ))}
              </ListItemsContainer>
            )}
          </>
        )}
      </Disclosure>
    </Container>
  )
}
