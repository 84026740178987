import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'
import React from 'react'

export interface OnboardingProviderProps {
  children: ReactNode | ReactNode[]
}

export interface OnboardingProviderDefinition {
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
  isStepEnabled: (currentStep: number) => boolean
  completeStep: (step: number) => void
}

const initData: OnboardingProviderDefinition = {
  activeStep: 1,
  setActiveStep: () => {},
  isStepEnabled: () => false,
  completeStep: () => {},
}

const OnboardingContext = createContext<OnboardingProviderDefinition>(initData)

const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const [activeStep, setActiveStep] = useState<number>(1)
  const [completedSteps, setCompletedSteps] = useState<number[]>([])

  // Handle completing a step to disable following steps if the previous were not completed
  const completeStep = (step: number) => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step])
    }
  }

  const isStepEnabled = (currentStep: number) => completedSteps.includes(currentStep)

  return (
    <OnboardingContext.Provider value={{ activeStep, setActiveStep, isStepEnabled, completeStep }}>
      {children}
    </OnboardingContext.Provider>
  )
}

export { OnboardingContext, OnboardingProvider }
