import React from 'react'

export const CancelIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 3c-4.959 0-9 4.041-9 9s4.041 9 9 9 9-4.041 9-9-4.041-9-9-9zm0 2c3.878 0 7 3.122 7 7s-3.122 7-7 7-7-3.122-7-7 3.122-7 7-7Z" />
      <path d="M8.703 7.703a1 1 0 0 0-.707.293 1 1 0 0 0 0 1.414l6.596 6.596a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.416L9.41 7.996a1 1 0 0 0-.707-.293Z" />
      <path d="M14.592 7.996 7.996 14.59a1 1 0 0 0 0 1.416 1 1 0 0 0 1.414 0l6.596-6.596a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0z" />
    </svg>
  )
}
