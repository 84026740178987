export const routes = {
  home: '/',
  profile: '/profile',
  login: '/login',
  onboarding: '/onboarding',
  organizations: '/organizations',
  authCallback: '/auth-callback',
  organizationSettings: '/settings',
  inviteAdmins: '/settings/invite',
  accessForbidden: '/forbidden',
  dashboard: '/legacy', // old page
  backOffice: {
    main: '/backoffice',
    users: '/backoffice/users',
    invite: '/backoffice/users/invite',
    profile: '/backoffice/profile',
    organizations: '/backoffice/organizations',
  },
  errors: {
    _404: '/404',
    _500: '/500',
  },
}
