import React, { SVGProps } from 'react'

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7071 7.29289C15.3166 6.90237 14.6834 6.90237 14.2929 7.29289C13.9024 7.68342 13.9024 8.31658 14.2929 8.70711L16.5858 11L5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13L16.5858 13L14.2929 15.2929C13.9024 15.6834 13.9024 16.3166 14.2929 16.7071C14.6834 17.0976 15.3166 17.0976 15.7071 16.7071L19.7065 12.7077C19.7069 12.7074 19.7073 12.707 19.7076 12.7066L20.4142 12L19.7071 11.2929L15.7071 7.29289Z"
        fill="inherit"
      />
    </svg>
  )
}
