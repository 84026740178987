import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Container } from '../../container'
import { HiddenLabel, Text } from '../../typography'
import { GenericInput } from './generic-input'
import { InputLabel } from './labels'
import { TextField } from './text-field'
import { LimitTextFieldProps } from './types'

export const LimitTextField: FC<LimitTextFieldProps> = ({
  id,
  name,
  value,
  placeholder,
  hiddenLabel,
  label,
  helperText,
  maxCharacters,
  autoFocus,
  onChange,
  onBlur,
  variant,
  warning,
  ...spacing
}) => {
  const [charsLimit, setCharsLimit] = useState<number>(maxCharacters)
  const [isCharsLimitVisible, setIsCharsLimitVisible] = useState<boolean>(false)

  useEffect(() => {
    setCharsLimit(maxCharacters - value.length)
  }, [value])

  return (
    <Container {...spacing}>
      {hiddenLabel && <HiddenLabel>{hiddenLabel}</HiddenLabel>}
      {label && <InputLabel htmlFor={name} labelText={label} />}
      <LimitInputContainer>
        <TextField
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          maxLength={maxCharacters}
          onChange={onChange}
          onBlur={onBlur}
          helperText={helperText}
          type="text"
          autoFocus={autoFocus}
          onFocus={() => setIsCharsLimitVisible(true)}
          warning={warning}
          variant={variant}
        />
        {isCharsLimitVisible && (
          <Text variant="smallTextRegular" as="span" color={charsLimit < 5 ? 'red' : 'greyDark'}>
            {charsLimit}
          </Text>
        )}
      </LimitInputContainer>
    </Container>
  )
}

export const LimitInputContainer = styled.div`
  position: relative;
  overflow: hidden;
  & > ${Text} {
    text-align: center;
    width: 2.5rem;
    line-height: 1;
    top: 50%;
    transform: translateY(-50%);
    right: 1.6rem;
    position: absolute;
  }
  ${GenericInput} {
    padding-right: 5rem;
  }
  ${space}
`
