import React from 'react'
import { MultiValue, SingleValue } from 'react-select'
import { SpaceProps, WidthProps } from 'styled-system'

export interface OptionType {
  value: string | number // @TODO do we need to support some generic option?
  label: string
  isDisabled?: boolean
  isPrimary?: boolean
}

export type Value = OptionType | OptionType[]

export interface SelectTextFieldProps extends SpaceProps, WidthProps {
  label: string
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  disabled?: boolean
  helperText?: string
  value?: Value
  multiple?: boolean
  onChange: (v: MultiValue<OptionType> | SingleValue<OptionType>) => void
  options: OptionType[]
  maxMenuHeight?: number
  isSearchable?: boolean
  closeMenuOnSelect?: boolean
  hideSelectedOptions?: boolean
  maxMultiChildrenAmount?: number
  tooltipText?: string
}
