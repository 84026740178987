import React from 'react'

export const HubspotIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="60"
      height="60"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 60 60"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.1343 43.2367C38.6416 43.2367 34.9996 39.6415 34.9996 35.2071C34.9996 30.7721 38.6416 27.1768 43.1343 27.1768C47.627 27.1768 51.2689 30.7721 51.2689 35.2071C51.2689 39.6415 47.627 43.2367 43.1343 43.2367ZM45.5695 19.7475V12.6041C47.4584 11.7234 48.7814 9.83717 48.7814 7.64814V7.48324C48.7814 4.46206 46.2774 1.99024 43.217 1.99024H43.0508C39.9903 1.99024 37.4864 4.46206 37.4864 7.48324V7.64814C37.4864 9.83717 38.8094 11.7242 40.6983 12.6049V19.7475C37.886 20.1767 35.3165 21.3217 33.1974 23.0068L13.3292 7.74984C13.4603 7.25286 13.5524 6.74124 13.5532 6.20342C13.5563 2.78233 10.7511 0.0046271 7.28401 4.01381e-06C3.81848 -0.00384856 1.00391 2.76615 1 6.18801C0.996101 9.60987 3.80131 12.3876 7.2684 12.3914C8.39782 12.393 9.44372 12.0763 10.3601 11.5616L29.9036 26.5704C28.2419 29.0469 27.2678 32.0134 27.2678 35.2071C27.2678 38.5504 28.3379 41.6433 30.1456 44.1867L24.2027 50.0542C23.7328 49.9147 23.2457 49.8176 22.729 49.8176C19.8809 49.8176 17.5713 52.0968 17.5713 54.9084C17.5713 57.7208 19.8809 60 22.729 60C25.5779 60 27.8867 57.7208 27.8867 54.9084C27.8867 54.3999 27.7884 53.9183 27.6471 53.4545L33.526 47.651C36.1946 49.6612 39.5181 50.8694 43.1343 50.8694C51.8972 50.8694 59 43.8569 59 35.2071C59 27.3764 53.1726 20.9072 45.5695 19.7475Z"
        fill="currentColor"
      />
    </svg>
  )
}
