import React from 'react'

export const GlossaryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      {...props}
    >
      <path d="M6 2a3.001 3.001 0 0 0-3 3v14a3.001 3.001 0 0 0 3 3h12a3.001 3.001 0 0 0 3-3V5a3 3 0 0 0-3-3H8Zm0 2h1v8a1 1 0 0 0 1.707.707L11 10.414l2.293 2.293A1 1 0 0 0 15 12V4h3a.999.999 0 0 1 1 1v14a.999.999 0 0 1-1 1H6a.999.999 0 0 1-1-1V5a.999.999 0 0 1 1-1Zm3 0h4v5.586l-1.293-1.293a1 1 0 0 0-1.414 0L9 9.586Z" />
    </svg>
  )
}
