import { FC } from 'react'
import styled from 'styled-components'
import { Button } from '../../../components/button'
import { Container } from '../../../components/container'
import { Heading } from '../../../components/typography'
import { useModal } from '../../../hooks/use-modal'
import { ConfirmationModalProps } from './types'

//TODO: add Storybook when it will work again

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  children,
  onSubmit,
  title,
  cancelLabel,
  submitLabel,
  isDisabled,
  variant,
  ...stylesProps
}) => {
  const { closeModal } = useModal()
  return (
    <Container {...stylesProps}>
      {title && (
        <Heading as="h2" mb="0.8rem">
          {title}
        </Heading>
      )}
      {children}
      <ButtonsContainer>
        <Button variant="secondary" width="100%" onClick={closeModal}>
          {cancelLabel}
        </Button>
        <Button
          type="submit"
          width="100%"
          onClick={onSubmit}
          disabled={isDisabled}
          variant={variant === 'warning' ? 'warning' : 'primary'}
        >
          {submitLabel}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-top: 4rem;
`
