import React from 'react'

export const NonTranslatableIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.682 3.902a.753.753 0 0 1-.305-.261l.305.26Zm5.283 1.894c.406-.593.757-1.203 1.033-1.824h-3.16l-1.75-1.5h2.47V2a.75.75 0 1 1 1.5 0v.472H10.615a.75.75 0 0 1 0 1.5h-.999c-.364.987-.893 1.928-1.505 2.806l-1.146-.982ZM3.802 5.72c.16.31.496.802.84 1.27.213.289.445.59.668.863a29.785 29.785 0 0 1-2.565 2.438.75.75 0 1 0 .972 1.142 31.489 31.489 0 0 0 2.63-2.492l1.287 1.24a.75.75 0 0 0 1.173-.171l-5.005-4.29Zm5.467 4.686-.189.493-.703 1.833a.75.75 0 1 0 1.4.538l.519-1.352h.737l-1.764-1.512Zm5.257 1.871-.53-1.378-1.757-4.583a.75.75 0 0 0-1.4 0l-.81 2.108 1.21 1.036.3-.782.593 1.548 2.394 2.051Z"
        clipRule="evenodd"
      />
      <path d="M1.484 2.428a.75.75 0 0 0-1.056.088.75.75 0 0 0 .088 1.056l8.746 7.4 4.254 3.6a.75.75 0 0 0 1.056-.088.75.75 0 0 0-.088-1.056l-4.254-3.6Z" />
    </svg>
  )
}
