import React from 'react'

export const RocketIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 16.5013C3 17.7613 2.5 21.5013 2.5 21.5013C2.5 21.5013 6.24 21.0013 7.5 19.5013C8.21 18.6613 8.2 17.3713 7.41 16.5913C7.02131 16.2203 6.50929 16.0059 5.97223 15.9893C5.43516 15.9727 4.91088 16.155 4.5 16.5013Z"
        stroke="#373234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.0002L9 12.0002C9.53214 10.6197 10.2022 9.29631 11 8.05025C12.1652 6.18723 13.7876 4.6533 15.713 3.59434C17.6384 2.53538 19.8027 1.98662 22 2.00025C22 4.72025 21.22 9.50025 16 13.0002C14.7369 13.799 13.3968 14.469 12 15.0002Z"
        stroke="#373234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.9995H4C4 11.9995 4.55 8.96953 6 7.99953C7.62 6.91953 11 7.99953 11 7.99953"
        stroke="#373234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15V20C12 20 15.03 19.45 16 18C17.08 16.38 16 13 16 13"
        stroke="#373234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
