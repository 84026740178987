import React, { FC } from 'react'
import { ScrollContainer } from 'ui'
import { TabProps } from './types'

export const ScrollableTab: FC<TabProps> = ({ children }) => {
  return (
    <ScrollContainer hideScrollbar flex="1 1 auto" minHeight="0">
      {children}
    </ScrollContainer>
  )
}
