import React from 'react'

export const LockIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
      <rect
        width="14"
        height="11"
        x="5"
        y="10"
        stroke="#A9A49C"
        strokeLinejoin="round"
        strokeWidth="2"
        rx="2"
      />
      <path stroke="#A9A49C" strokeLinecap="round" strokeWidth="2" d="M12 14v3" />
      <path stroke="#A9A49C" strokeWidth="2" d="M16 11V7a4 4 0 1 0-8 0v4" />
    </svg>
  )
}
