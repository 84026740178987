import { GetServerSidePropsContext } from 'next'
import { Session } from '~/server-api/auth'
import { getOnboardingDetails } from '~/server-api/organizations-api'

export enum CHECK_ONBOARDED_STATUSES {
  NOT_ONBOARDED = 'NOT_ONBOARDED',
  ONBOARDED = 'ONBOARDED',
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
}

export const getOnboardingStatus = async ({
  organizationId,
  organizationToken,
}: {
  organizationId: string
  organizationToken: string
}) => {
  const { organization } = await getOnboardingDetails({
    organizationId: organizationId,
    accessToken: organizationToken!,
  })

  if (!organization) {
    return CHECK_ONBOARDED_STATUSES.ORGANIZATION_NOT_FOUND
  }

  const { onboardingCompleted } = organization

  return onboardingCompleted
    ? CHECK_ONBOARDED_STATUSES.ONBOARDED
    : CHECK_ONBOARDED_STATUSES.NOT_ONBOARDED
}

export const checkOnboardingStatus = async (ctx: GetServerSidePropsContext) => {
  const organizationId = ctx.query.organizationId as string
  const session = await Session.fromCookies(ctx.req.cookies, { organizationId })

  session.setCookies(ctx.res)

  if (!organizationId || typeof organizationId !== 'string') {
    return {
      redirect: {
        destination: '/organizations',
        permanent: false,
      },
    }
  }

  const onboardingStatus = await getOnboardingStatus({
    organizationId,
    organizationToken: session.organizationToken!,
  })

  if (onboardingStatus === CHECK_ONBOARDED_STATUSES.ORGANIZATION_NOT_FOUND) {
    return {
      redirect: {
        destination: '/organizations',
        permanent: false,
      },
    }
  }

  if (onboardingStatus === CHECK_ONBOARDED_STATUSES.NOT_ONBOARDED) {
    return {
      redirect: {
        destination: `/${organizationId}/build-subscription`,
        permanent: false,
      },
    }
  }

  return null
}
