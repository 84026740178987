import React from 'react'

export const JcbIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      {...props}
    >
      <path
        fill="#047AB1"
        d="M1 16h4.518c1.028 0 2.259-1.195 2.259-2.133V0H3.259C2.23 0 1 1.195 1 3.2V16Z"
      />
      <path
        fill="#fff"
        d="M3.564 10.816c-.867 0-1.73-.115-2.564-.341V9.3c.647.378 1.386.591 2.146.619.87 0 1.355-.576 1.355-1.365V5.333h2.146v3.222c0 1.258-.7 2.26-3.083 2.26Z"
      />
      <path
        fill="#D42D06"
        d="M8.906 16h4.518c1.028 0 2.259-1.195 2.259-2.133V0h-4.518c-1.028 0-2.259 1.195-2.259 3.2V16Z"
      />
      <path
        fill="#fff"
        d="M8.906 6.08c.655-.597 1.785-.97 3.614-.885.995.042 2.034.32 2.034.32v1.184A4.785 4.785 0 0 0 12.6 6.09c-1.39-.118-2.237.618-2.237 1.909 0 1.29.847 2.027 2.237 1.92a4.854 4.854 0 0 0 1.954-.619v1.174s-1.04.288-2.034.33c-1.83.086-2.959-.288-3.614-.885V6.08Z"
      />
      <path
        fill="#67B637"
        d="M16.813 16h4.517c1.028 0 2.26-1.195 2.26-2.133V0H19.07c-1.027 0-2.259 1.195-2.259 3.2V16Z"
      />
      <path
        fill="#fff"
        d="M22.46 9.28c0 .853-.7 1.387-1.638 1.387h-4.01V5.333h3.649l.26.01c.824.044 1.434.502 1.434 1.292 0 .618-.418 1.152-1.175 1.28v.032c.836.053 1.48.554 1.48 1.333Zm-2.88-3.104a1.087 1.087 0 0 0-.147-.01h-1.355v1.343h1.502a.667.667 0 0 0 .52-.672c0-.362-.238-.608-.52-.661Zm.17 2.176a1.028 1.028 0 0 0-.182-.01h-1.49v1.46h1.49l.181-.02c.283-.065.52-.342.52-.715 0-.374-.226-.64-.52-.715Z"
      />
    </svg>
  )
}
