import React from 'react'

export const PlantIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 21H18"
        stroke="#373234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 21C15.5 18.25 10.8 13.96 13 10"
        stroke="#373234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.34615 9.20736C10.6154 10.1731 11.4231 11.8631 12 13.6738C9.69231 14.1566 7.96154 14.1566 6.46154 13.3116C5.07692 12.5873 3.80769 11.0181 3 8.24166C6.23077 7.63809 8.07692 8.24166 9.34615 9.20736Z"
        stroke="#373234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2868 5.33333C13.3979 6.72348 12.9508 8.35051 13.0043 10C15.2196 9.88333 16.8519 9.3 18.0179 8.36667C19.1838 7.2 19.8834 5.68333 20 3C16.8519 3.11667 15.3362 4.16667 14.2868 5.33333Z"
        stroke="#373234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
