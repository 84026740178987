import React from 'react'

export const PuzzleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M10 2a4.003 4.003 0 0 0-4 4H4a2.001 2.001 0 0 0-2 2v2.15c0 .586.312 1.092.69 1.393A2.07 2.07 0 0 0 4 12a2.001 2.001 0 0 1 0 4 2.07 2.07 0 0 0-1.31.457c-.378.301-.69.807-.69 1.393V20a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2a4.002 4.002 0 0 0 3.695-5.531 3.996 3.996 0 0 0-2.164-2.164A4.002 4.002 0 0 0 18 10V8a2.001 2.001 0 0 0-2-2h-2a4.002 4.002 0 0 0-4-4Zm0 2a2.001 2.001 0 0 1 2 2c0 .484.156.933.457 1.31.302.378.808.69 1.393.69H16v2.15c0 .585.312 1.093.69 1.395.377.301.827.455 1.31.455a2 2 0 0 1 0 4c-.484 0-.933.156-1.31.457-.378.302-.69.808-.69 1.393V20H4v-2a4.002 4.002 0 0 0 3.695-5.531A3.999 3.999 0 0 0 4 10V8h2.15c.585 0 1.093-.312 1.395-.69C7.846 6.934 8 6.484 8 6a2.001 2.001 0 0 1 2-2Z" />
    </svg>
  )
}
