import React from 'react'
import { SpaceProps } from 'styled-system'

export interface ToggleButtonProps extends SpaceProps {
  id: string
  label: string
  desc?: string
  disabled?: boolean
  checked: boolean
  unlabeled?: boolean
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
}

export interface InputWrapperProps {
  unlabeled?: boolean
  disabled?: boolean
}
