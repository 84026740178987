export const PlaceholderCardIcon = () => {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3683_52404)">
        <path
          d="M21.75 0.25H2.25C1.14543 0.25 0.25 1.14543 0.25 2.25V13.75C0.25 14.8546 1.14543 15.75 2.25 15.75H21.75C22.8546 15.75 23.75 14.8546 23.75 13.75V2.25C23.75 1.14543 22.8546 0.25 21.75 0.25Z"
          fill="#F6F3EE"
        />
      </g>
      <defs>
        <clipPath id="clip0_3683_52404">
          <rect width="24" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
