import React from 'react'

export const VisaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" {...props}>
      <path
        fill="#fff"
        stroke="#C6C6C7"
        strokeWidth=".5"
        d="M2.25.5h19.5c.966 0 1.75.784 1.75 1.75v11.5a1.75 1.75 0 0 1-1.75 1.75H2.25A1.75 1.75 0 0 1 .5 13.75V2.25C.5 1.284 1.284.5 2.25.5Z"
      />
      <path
        fill="#1434CB"
        d="M3.703 5.86A6.91 6.91 0 0 0 2 5.223l.027-.118h2.606c.354.013.64.118.734.489l.566 2.668.174.804 1.586-3.96h1.713L6.86 10.906H5.147L3.703 5.861Zm6.964 5.053h-1.62l1.013-5.808h1.62l-1.013 5.808Zm5.873-5.666-.22 1.254-.147-.061a2.938 2.938 0 0 0-1.207-.223c-.64 0-.926.254-.933.5 0 .273.347.452.914.718.933.414 1.366.92 1.36 1.581-.014 1.205-1.12 1.984-2.82 1.984-.727-.006-1.427-.149-1.807-.31l.226-1.303.214.093c.527.216.873.309 1.52.309.466 0 .967-.18.973-.569 0-.253-.213-.439-.84-.723-.613-.278-1.433-.741-1.42-1.575C12.36 5.79 13.487 5 15.087 5c.626 0 1.133.13 1.453.247Zm2.153 3.609h1.347c-.067-.29-.374-1.68-.374-1.68l-.113-.501c-.08.216-.22.568-.213.556l-.647 1.625Zm2-3.75L22 10.912h-1.5l-.193-.87h-2.08l-.34.87h-1.7l2.406-5.326c.167-.377.46-.482.847-.482h1.253Z"
      />
    </svg>
  )
}
