import React from 'react'

export const KeyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.354 2.596a6.842 6.842 0 0 0-6.645 4.572c-.435 1.24-.422 2.561-.121 3.83l-6.295 6.295A1 1 0 0 0 2 18v2.695c0 .492.18 1 .54 1.36.358.359.867.539 1.358.539h3.594a1 1 0 0 0 1-1v-1.696h1.694a1 1 0 0 0 1-1v-1.695h.796a1 1 0 0 0 .707-.29l.907-.907c1.269.3 2.59.314 3.83-.121a6.839 6.839 0 0 0-2.072-13.29zm-.057 2a4.835 4.835 0 0 1 4.672 4.293 4.838 4.838 0 0 1-6.399 5.113 1 1 0 0 0-1.037.238l-.965.963h-1.382a1 1 0 0 0-1 1v1.695H7.492a1 1 0 0 0-1 1v1.696H4v-2.18l6.354-6.353a1 1 0 0 0 .238-1.038 4.834 4.834 0 0 1 1.998-5.689 4.839 4.839 0 0 1 2.707-.738z" />
      <path d="M16.5 6.594c-.817 0-1.5.683-1.5 1.5 0 .816.683 1.5 1.5 1.5s1.5-.684 1.5-1.5c0-.817-.683-1.5-1.5-1.5z" />
    </svg>
  )
}
