import React from 'react'

export const CheckCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.457 5.172a7 7 0 0 1 4.392.434 1 1 0 1 0 .814-1.827A9 9 0 1 0 21 12.005v-.736a1 1 0 1 0-2 0v.735a7 7 0 1 1-8.543-6.832Zm10.25 1.14a1 1 0 0 0-1.415-1.414L12 12.198l-1.693-1.693a1 1 0 1 0-1.414 1.415l2.4 2.4a1 1 0 0 0 1.415 0l8-8.009Z" />
    </svg>
  )
}
