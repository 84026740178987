import React from 'react'

export const IndicatorWatchedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.764 2.3c-2.329-.315-4.71.855-6.381 3.274a.75.75 0 0 0 .191 1.043.75.75 0 0 0 1.043-.191c1.429-2.07 3.227-2.872 4.946-2.639.145.02.29.049.435.084 1.195.29 2.389 1.112 3.385 2.555a.75.75 0 0 0 1.043.191.75.75 0 0 0 .191-1.043C10.452 3.887 8.96 2.802 7.354 2.412a5.795 5.795 0 0 0-.59-.111Z" />
      <circle cx="6" cy="7" r="2" />
    </svg>
  )
}
