import React from 'react'

export const DiscoverIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        stroke="#000"
        strokeOpacity=".2"
        strokeWidth=".5"
        d="M21.997 15.75H22c.956.008 1.74-.773 1.751-1.746V2.006a1.788 1.788 0 0 0-.52-1.25A1.72 1.72 0 0 0 21.997.25H2.001A1.718 1.718 0 0 0 .77.757c-.33.33-.517.779-.521 1.247v11.99c.004.47.191.92.52 1.25.329.328.771.51 1.233.506h19.994Zm0 .5h-.002.002Z"
      />
      <path
        fill="#F27712"
        d="M12.613 16h9.385a1.986 1.986 0 0 0 2.003-1.97v-2.358A38.739 38.739 0 0 1 12.613 16Z"
      />
      <path
        fill="#000"
        d="M23.173 9.296h-.852l-.96-1.266h-.091v1.266h-.696V6.152H21.6c.803 0 1.267.33 1.267.926 0 .489-.29.803-.811.903l1.117 1.315Zm-1.026-2.193c0-.306-.232-.463-.662-.463h-.215v.952h.198c.447 0 .679-.166.679-.489Zm-4.006-.951h1.97v.53h-1.275v.703h1.225v.538h-1.225v.852h1.275v.53h-1.97V6.152Zm-2.234 3.227L14.4 6.143h.762l.952 2.119.96-2.119h.744L16.297 9.38h-.389Zm-6.298-.008c-1.06 0-1.887-.72-1.887-1.655 0-.91.844-1.647 1.903-1.647.298 0 .547.058.853.19v.728a1.241 1.241 0 0 0-.87-.355c-.661 0-1.166.48-1.166 1.084 0 .637.496 1.092 1.2 1.092.314 0 .554-.1.836-.347v.728a2.13 2.13 0 0 1-.87.182ZM7.507 8.336c0 .613-.505 1.035-1.233 1.035-.53 0-.91-.182-1.233-.596l.455-.389c.157.281.422.422.753.422.314 0 .538-.19.538-.438 0-.141-.066-.249-.207-.331a2.88 2.88 0 0 0-.48-.183c-.654-.206-.877-.43-.877-.869 0-.513.48-.902 1.109-.902.397 0 .753.125 1.05.356l-.364.414a.761.761 0 0 0-.562-.248c-.298 0-.513.149-.513.347 0 .166.124.257.538.398.794.248 1.026.48 1.026.993v-.009ZM4.089 6.152h.695v3.153h-.695V6.152ZM1.854 9.305H.828V6.152h1.026c1.126 0 1.904.645 1.904 1.572 0 .472-.232.919-.637 1.217-.348.248-.737.364-1.275.364h.008Zm.811-2.367c-.231-.182-.496-.248-.951-.248h-.19v2.085h.19c.447 0 .728-.083.951-.248.24-.199.381-.497.381-.803 0-.306-.14-.596-.38-.786Z"
      />
      <path
        fill="#F27712"
        d="M12.413 6.069c-.91 0-1.655.728-1.655 1.63 0 .96.712 1.68 1.655 1.68a1.64 1.64 0 0 0 1.655-1.655c0-.927-.72-1.655-1.655-1.655Z"
      />
    </svg>
  )
}
