import React from 'react'

export const CameraIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currenColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m8.732 2.5-2 3H3A2.5 2.5 0 0 0 .5 8v11A2.5 2.5 0 0 0 3 21.5h18a2.5 2.5 0 0 0 2.5-2.5V8A2.5 2.5 0 0 0 21 5.5h-3.732l-2-3zm.536 1h5.464l2 3H21A1.5 1.5 0 0 1 22.5 8v11a1.5 1.5 0 0 1-1.5 1.5H3A1.5 1.5 0 0 1 1.5 19V8A1.5 1.5 0 0 1 3 6.5h4.268z" />
      <path d="M12 8.5c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5zm0 1c1.939 0 3.5 1.561 3.5 3.5s-1.561 3.5-3.5 3.5A3.492 3.492 0 0 1 8.5 13c0-1.939 1.561-3.5 3.5-3.5z" />
    </svg>
  )
}
