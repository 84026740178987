import React from 'react'

export const BookIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      {...props}
    >
      <path
        stroke="#A9A49C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6.286v12.952m0-13.714C9.48 4 8.76 4 3 4v14.476c5.76 0 6.48 0 9 1.524 2.52-1.524 4.68-1.524 9-1.524V4c-5.76 0-6.48 0-9 1.524Z"
      />
    </svg>
  )
}
